import React from "react";
import { Footer } from "react-bulma-components";

const MyFooter = () => {
  return (
    <footer>
      <Footer>
        <div className="content has-text-centered">
          <p>
            <strong>Panos Axiotis</strong>{" "}
            <p>
              The page is currently under construction contact me at{" "}
              <a href="mailto:me@panosaxiotis.com">me@panosaxiotis.com</a>
            </p>
          </p>
        </div>
      </Footer>
    </footer>
  );
};

export default MyFooter;
