import React from "react";

class Home extends React.Component {
  componentDidMount() {
    document.title = "My place - Panos Axiotis";
  }
  render() {
    return (
      <main>
        <div>
          <p>young and ambitious</p>
          <span>7 years experience in web dev</span>
        </div>
      </main>
    );
  }
}

export default Home;
