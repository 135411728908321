import React from "react";

class Contact extends React.Component {
  componentDidMount() {
    document.title = "Try to reach me - Panos Axiotis";
  }
  render() {
    return (
      <main>
        <div>
          <p>contact me at</p>
          <span>
            <a href="mailto:me@panosaxiotis.com">me@panosaxiotis.com</a>
          </span>
        </div>
      </main>
    );
  }
}
export default Contact;
