import React from "react";
import { NavLink } from "react-router-dom";
import "react-bulma-components/dist/react-bulma-components.min.css";
import { Navbar } from "react-bulma-components";
import logo from "../logo.svg";

class NavBar extends React.Component {
  render() {
    return (
      <Navbar>
        <div className="navbar-brand">
          {" "}
          <img src={logo} width="auto" height="50px" />
          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navMenu"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navMenu" className="navbar-menu">
          <div className="navbar-end">
            <NavLink
              exact
              activeClassName="nav-active"
              to="/"
              className="navbar-item"
            >
              Home
            </NavLink>

            <NavLink
              className="navbar-item"
              activeClassName="nav-active"
              to="/about"
            >
              About Me
            </NavLink>
            <NavLink
              activeClassName="nav-active"
              to="/contact"
              className="navbar-item"
            >
              Contact
            </NavLink>
          </div>
        </div>
      </Navbar>
    );
  }
}

export default NavBar;
