import React from "react";

class About extends React.Component {
  componentDidMount() {
    document.title = "Get to know me - Panos Axiotis";
  }
  render() {
    return (
      <main>
        <div>
          <p>coming soon</p>
        </div>
      </main>
    );
  }
}
export default About;
